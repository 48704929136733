export const RESERVATIONS = 'reservations'
export const GRID = 'grid'
export const FLOORPLAN = 'floorplan'
export const GUESTLIST = 'guestlist'
export const REQUESTS = 'requests'
export const CLIENTS = 'clients'
export const INSIGHTS = 'insights'
export const REPORTS = 'reports'
export const MARKETING = 'marketing'
export const ORDERS = 'orders'
export const HELP = 'help'
export const SETTINGS = 'settings'
export const PROFILE = 'profile'
export const LOGOUT = 'logout'
export const PRIVATE_EVENTS = 'private-events'
